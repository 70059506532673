import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { HiSelector } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";



// const responseResult = {
//     tHead: [
//         {
//             "headName": "CATEGORY"
//         },
//         {
//             "headName": "TITLE"
//         },
//         {
//             "headName": "DATE"
//         },
//         {
//             "headName": "URL"
//         }
//     ],
//     tBody: [
//         {
//             "category": "Credit and Refinance Circulars",
//             "title": "Test",
//             "date": "09/08/2024",
//             "url": "https://tgbdemocms.techelecon.net/nav_img/684_1675770300_925_1675764559_Nari Kalyan Loan (1).pdf",
//             "id": "2",
//             "new": "New"
//         }
//     ]
// }

const AgendaCirular = ({ lang }) => {
    const [data, setData] = useState();
    const [table, setTable] = useState();
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("");
    const [mainTable, setMainTable] = useState();
    const [order, setOrder] = useState("ASC");
    const [addCommentId, setAddCommentId] = useState('')
    const [viewComment, setViewComment] = useState('')

    const [comments, setComments] = useState('')

    const navigate = useNavigate()




    const sorting = (el) => {
        if (order === "ASC") {
            const sorted = [...data].sort((a, b) =>
                a[el].toLocaleLowerCase() > b[el].toLocaleLowerCase() ? 1 : -1
            );
            setData(sorted);
            setOrder("DSC");
        }
        if (order === "DSC") {
            const sorted = [...data].sort((a, b) =>
                a[el].toLocaleLowerCase() < b[el].toLocaleLowerCase() ? 1 : -1
            );
            setData(sorted);
            setOrder("ASC");
        }
    };

    const sortingDate = (el) => {
        if (order === "ASC") {
            const sorted = [...data].sort(
                (a, b) =>
                    a[el].toLocaleLowerCase().split("/").reverse().join("") -
                    b[el].toLocaleLowerCase().split("/").reverse().join("")
            );
            setData(sorted);
            setOrder("DSC");
        }
        if (order === "DSC") {
            const sorted = [...data].sort(
                (a, b) =>
                    b[el].toLocaleLowerCase().split("/").reverse().join("") -
                    a[el].toLocaleLowerCase().split("/").reverse().join("")
            );
            setData(sorted);
            setOrder("ASC");
        }
    };

    const dis = [];

    for (let i = 0; i < data?.length; i++) {
        dis.push(data[i].category);
    }

    const uniq = dis.filter((item, index) => dis.indexOf(item) === index);

    let Access_token = sessionStorage.getItem("Access_token");




    const atmLocateData = () => {
        axios
            .post(" https://tgbdemo.techelecon.net/api/agenda_circular_table.php", {
                lang: lang, AccessToken: Access_token
            })
            .then((response) => {
                if (response.data.responseStatus) {
                    setData(response.data.responseResult.tBody);
                } else {
                    navigate('/log-in')
                }
            })
            .catch((error) => {
                swal('You need to provide valid credentials');
            });
    };


    const downloadFils = async (url) => {
        try {
            const response = await fetch(`https://tgbdemo.techelecon.net/api/DownloadDocFile.php`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ docnm: url, AccessToken: Access_token })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch document');
            }

            // Convert response to Blob (PDF file)
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link and trigger the download
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = url || 'document.pdf'; // Set file name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Revoke the object URL after some time
            setTimeout(() => window.URL.revokeObjectURL(blobUrl), 100);

        } catch (error) {
            console.error('Error:', error);
        }
    };


    const tBody = data
        ?.filter((el) => {
            if (filter.toLocaleLowerCase() == "") {
                return el;
            } else if (
                filter.toLocaleLowerCase() == el.category.toLocaleLowerCase()
            ) {
                return el;
            }
        })
        .filter((el) => {
            if (search == "") {
                return el;
            } else if (el.category.toLowerCase().includes(search.toLowerCase())) {
                return el;
            } else if (el.title.toLowerCase().includes(search.toLowerCase())) {
                return el;
            } else if (el.date.toLowerCase().includes(search.toLowerCase())) {
                return el;
            }
        })
        ?.map((el) => {
            return (
                <tr>
                    <td>
                        <p class="px-3 py-1 m-0">
                            {el.title}{" "}
                            {el.new == "New" ? (
                                <span class="marquee_new mx-3 text-danger fw-bold">
                                    {el.new}
                                </span>
                            ) : (
                                ""
                            )}
                        </p>
                    </td>
                    <td>
                        <p class="px-3 py-1 m-0">{el.date}</p>
                    </td>
                    {/* <td>
                        <p
                            class="text-success px-3 m-0"
                            style={{ cursor: "pointer", fontWeight: "500" }}
                        >
                            <a
                                class="text-success"
                                href={`${el.url}`}
                                target="_blanck"
                                download
                            >
                                Download
                            </a>
                        </p>
                    </td> */}
                    <td>
                        <p
                            class="text-success px-3 m-0"
                            style={{ cursor: "pointer", fontWeight: "500" }}
                        >
                            <a
                                class="text-success"
                                onClick={() => downloadFils(el.url)}
                            >
                                Download
                            </a>
                        </p>
                    </td>
                    <td>
                        <div class='d-flex gap-3 align-items-center'>
                            <button class='btn btn-success  text-nowrap' onClick={() => setAddCommentId(el?.id)}>Add Comment</button>
                            <button class='btn btn-outline-success text-nowrap ' onClick={() => handelViewComments(el?.id)}>View Comment</button>

                        </div>
                    </td>
                </tr>
            );
        });
    useEffect(() => {
        atmLocateData();
    }, [lang]);

    const description = (el) => {
        setFilter(el);
    };

    const handelAddComments = () => {
        if (comments.length > 0 && addCommentId) {
            axios
                .post(" https://tgbdemo.techelecon.net/api/add_agenda_circular_comment.php", {
                    CircularId: addCommentId, Comments: comments, AccessToken: Access_token
                })
                .then((response) => {
                    if (response.data.responseStatus) {
                        swal(response.data.responseMessage);
                        setAddCommentId('')
                    }
                })
                .catch((error) => {
                    swal("Something wrong!");
                    setAddCommentId('')
                });
        } else {
            swal("Please Add your comments !");
        }
    }


    useEffect(() => {

    })
    const handelViewComments = (el) => {
        axios
            .post(" https://tgbdemo.techelecon.net/api/agenda_circular_comment_list.php", {
                CircularId: el, AccessToken: Access_token
            })
            .then((response) => {
                if (response.data.responseStatus) {
                    swal(response.data.responseMessage);
                    setViewComment(response.data.responseResult)
                }
            })
            .catch((error) => {
                swal("Something wrong!");
            });

    }

    return (
        <div class="downloadForm">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Agenda Circulars </title>
                <meta name="description" content="Agenda Circulars" />
            </Helmet>
            {addCommentId &&
                <div class='d-flex align-items-center justify-content-center' style={{ position: 'fixed', top: '0', left: '0', height: '100%', width: '100%', background: 'rgba(0,0,0,0.5)', zIndex: '9' }}>
                    <div class='col-md-6 col-11 rounded-4 bg-light'>
                        <div class='col-11 m-auto my-4 text-start'>
                            <label htmlFor="comments">Comments</label>
                            <textarea type='text' class='form-control' id="comments" value={comments} onChange={(e) => setComments(e.target.value)} />
                            <div class='  my-3 d-flex align-items-center justify-content-center gap-3'>
                                <button class='btn btn-success' onClick={handelAddComments}>Submit</button>
                                <button class='btn  btn-outline-danger ' onClick={() => setAddCommentId('')}>Cancel</button>

                            </div>

                        </div>
                    </div>
                </div>
            }

            {viewComment &&
                <div class='d-flex align-items-center justify-content-center' style={{ position: 'fixed', top: '0', left: '0', height: '100%', width: '100%', background: 'rgba(0,0,0,0.5)', zIndex: '9' }}>
                    <div class='col-md-6 col-11 rounded-4 bg-light'>
                        <p class='fs-3 fw-bold my-3'>Comments</p>
                        <div
                            class="col-11 m-auto"
                            style={{ overflowX: "auto", height: "100%", overflowY: "hidden" }}
                        >
                            <table class="table " style={{ width: "100%" }} id="sortTable">
                                <thead>
                                    <tr>
                                        <th
                                            style={{ background: "#f0f0f0" }}
                                        >
                                            <p class="px-3 m-0 ">
                                                Name
                                            </p>
                                        </th>
                                        <th
                                            style={{ background: "#f0f0f0" }}
                                        >
                                            <p class="px-3 m-0 ">
                                                Date
                                            </p>
                                        </th>
                                        <th
                                            style={{ background: "#f0f0f0" }}
                                        >
                                            <p class="px-3 m-0 ">
                                                Comments
                                            </p>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        viewComment?.map((el) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <p class="px-3 py-1 m-0">
                                                            {el.doc_nm}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p class="px-3 py-1 m-0">{el.date}</p>
                                                    </td>
                                                    <td>
                                                        <p class="px-3 py-1 m-0">{el.comment}</p>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div class='col-11 m-auto my-4 text-start'>


                            <div class='  my-3 d-flex align-items-center justify-content-center gap-3'>
                                <button class='btn  btn-outline-danger ' onClick={() => setViewComment('')}>Close</button>
                            </div>

                        </div>
                    </div>
                </div>
            }
            <div class="p-3 text-start" style={{ background: "#f3f3f3" }}>
                <p class="m-0">
                    {" "}
                    <span>Agenda Circulars</span>
                </p>
                <p class="fs-2 fw-semibold m-0">Agenda Circulars</p>
            </div>
            <div class="row">
                <div class="col-md-3 col-11 p-3" style={{ margin: "0 auto" }}>
                    <div
                        class="text-start p-2"
                        onClick={() => description("")}
                        style={{ background: "#BE3F62", cursor: "pointer" }}
                    >
                        <p class="text-white fw-bold m-0">All Category</p>
                    </div>
                    {uniq?.map((el) => {
                        return (
                            <div
                                class="text-start p-2 border"
                                onClick={() => description(`${el}`)}
                                style={{ color: "#BE3F62", cursor: "pointer" }}
                            >
                                <p class="fw-semibold m-0">
                                    {el}{" "}
                                    {el.new == "New" ? (
                                        <span class="marquee_new mx-3 text-danger fw-bold">
                                            {el.new}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </p>
                            </div>
                        );
                    })}
                </div>
                <div class="col-md-9 col-11 p-3 " style={{ margin: "0 auto" }}>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search here "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    <div
                        class="mt-4"
                        style={{ overflowX: "auto", height: "100%", overflowY: "hidden" }}
                    >
                        <table class="table " style={{ width: "100%" }} id="sortTable">
                            <thead>
                                <tr>
                                    <th
                                        style={{ background: "#f0f0f0" }}
                                        onClick={() => sorting("title")}
                                    >
                                        <p class="px-3 m-0 ">
                                            Description{" "}
                                            <span>
                                                <HiSelector />
                                            </span>
                                        </p>{" "}
                                    </th>
                                    <th
                                        style={{ background: "#f0f0f0" }}
                                        onClick={() => sortingDate("date")}
                                    >
                                        <p class="px-3 m-0 ">
                                            Date{" "}
                                            <span>
                                                <HiSelector />
                                            </span>
                                        </p>{" "}
                                    </th>
                                    <th style={{ background: "#f0f0f0" }}>
                                        <p class="px-3 m-0 ">Download</p>
                                    </th>
                                    <th style={{ background: "#f0f0f0" }}>
                                        <p class="px-3 m-0 ">Action</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{tBody}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgendaCirular;


