import axios from "axios";
import React from "react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./style/login.css";
import swal from "sweetalert";
import { BiHide, BiShow } from "react-icons/bi";
import md5 from "md5";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const [state, setState] = useState({});
  const [userFormData, setUserFormData] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [key, setKey] = useState("");
  const [criptKey, setCriptKey] = useState("");
  const [criptPass, setCriptPass] = useState("");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}${month}${day}`;

  const handelUserFormData = (e) => {
    const { name, value } = e.target;
    setUserFormData({ ...userFormData, [name]: value });
  };

  useEffect(() => {
    setKey(md5(`tgb_${formattedDate}_${email}`));
  }, [email, formattedDate]);

  useState(() => {
    setCriptKey(md5(key));
  }, [key]);

  useEffect(() => {
    const logData = { ...userFormData };
    logData.Key = key;
    logData.Pass = password;
    logData.Email = email;

    setUserFormData(logData);
  }, [key, email, password]);

  const loginUser = () => {
    axios
      .post("https://tgbdemo.techelecon.net/api/login.php", userFormData)
      .then((res) => {
        if (res.status === 200) { 
          setState(res.data);
          window.sessionStorage.setItem("Access_token", res.data.responseToken);
          window.sessionStorage.setItem("roleType", res.data.responseRoleType);
          swal(res.data.responseMessage); 
            navigate("/download-form"); 
        } 
      })
      .catch((err) => { 
        swal('You need to provide valid credentials');
      });
  };

  return (
    <div class="login">
      <Helmet>
            <meta charSet="utf-8" />
            <title>Login User</title>
            <meta name="description" content="Login User" />
        </Helmet>
      <div class="login1 text-start p-3">
        <p class="fs-1 fw-bold">Log-in</p>
        <input
          class="form-control p-2 "
          type="text"
          placeholder="User Id"
          name="Email"
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="off" 
        />
        <br />
        <div class="input-group">
          <input
            class="form-control p-2"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            name="Pass"
            onChange={(e) => setPassword(md5(e.target.value))}
            autoComplete="new-password" 
          />
          {showPassword ? (
            <span
              class="input-group-text"
              onClick={() => setShowPassword(false)}
            >
              <BiHide />
            </span>
          ) : (
            <span
              class="input-group-text"
              onClick={() => setShowPassword(true)}
            >
              <BiShow />
            </span>
          )}
        </div>
        <br />
        <button
          class="btn btn-success p-2 px-3"
          id="success"
          onClick={loginUser}
        >
          {" "}
          Submit
        </button>
        <button
          class="btn btn-success p-2 px-3 mx-4"
          onClick={() => navigate("/forget-pasword")}
        >
          {" "}
          Forget Password
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
